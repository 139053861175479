import { useLazyQuery } from "@apollo/client";
import { useEffect, useLayoutEffect, useState } from "react";
import {
  useLocation,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import Select from "react-select";
import {
  CLIENTTESTSREPORTS,
  GETCLIENTSNAMEANDIDS,
} from "../../../Graphql/Queries";
import MyLoader from "../../MiroComponents/PreLoader";
import { ClientStats } from "../../NewComponents/ClientStats";
import { ClientStrategyandRoadmap } from "../../NewComponents/ClientStrategyandRoadmap";
import { ClientTestCardSection } from "../../NewComponents/ClientTestCardsSection";
import { ClientTestsOverview } from "../../NewComponents/ClientTestsOverview";
import { ClientTopLearningsandNextstep } from "../../NewComponents/ClientTopLearningsandNextstep";
import { LiveReportsIcon } from "../../Shared/Icons";
import useStore from "../../../Store";
import NoDataFound from "../../MiroComponents/NoDataFound/NoDataFound";
import AddRevPerMonth from "../../NewComponents/AddRevPerMonth/AddRevPerMonth";
import TestsOverview from "../../NewComponents/TestsOverview/TestsOverview";
import ReportsList from "../../NewComponents/ReportsList/ReportsList";
import PerformanceOverview from "../../NewComponents/PerformanceOverview/PerformanceOverview";
// import AddRevPerMonth from "../../NewComponents/AddRevPerMonth/AddRevPerMonth";

export const ClientReportsAdmin = () => {
  const [searchParams, setSearchParams] = useSearchParams({});
  const selectedClient = useStore((state) => state.selectedClient);
  const viewId = searchParams.get("viewId");
  const [clientStatsReports, setClientStatsReports] = useState({
    show: false,
  });
  const [statusFilter, setStatusFilter] = useState("ended");
  const [clientTestReports] = useLazyQuery(CLIENTTESTSREPORTS);

  useEffect(() => {
    if (selectedClient && viewId && statusFilter === "ended") {
      setClientStatsReports({
        ...clientStatsReports,
        loading: true,
        show: true,
      });
      (async () => {
        const { data, loading, error } = await clientTestReports({
          variables: {
            viewId,
          },
        });
        setClientStatsReports({
          clientTestReports: data?.clientTestReports,
          loading,
          error,
          show: !loading,
        });
      })();
    }
  }, [
    selectedClient,
    viewId,
    statusFilter,
    clientTestReports,
    setClientStatsReports,
  ]);

  if (!selectedClient) return <NoDataFound />;
  return (
    <>
      <section className="live-reports">
        <div className="live-reports-inner">
          {/* <div className="client-data-wrapper">
            <div className="client-details">
              <div className="properties-dropdown">
                <h1 className="section-heading">Properties:</h1>
                <PropertySelector
                  options={selectedClient?.value?.gaproperties}
                />
              </div>
            </div>
          </div> */}
        </div>
      </section>
      <>
        {/* <ClientStats client={selectedClient?.value?._id} viewId={viewId} /> */}
        <PerformanceOverview client={selectedClient?.value?._id} />
        <AddRevPerMonth
          client={selectedClient?.value?._id}
          // theme={state?.theme}
        />
        <TestsOverview client={selectedClient?.value?._id} />

        <ReportsList client={selectedClient?.value?._id} />
        {/* <ClientStrategyandRoadmap
            clientId={selectedClient?.value?._id}
            selectedProperty={viewId}
            showSection={clientStatsReports?.show}
          />
          <ClientTopLearningsandNextstep
            clientId={selectedClient?.value?._id}
            selectedProperty={viewId}
            showSection={clientStatsReports?.show}
          />

          <ClientTestsOverview
            statistics={clientStatsReports?.clientTestReports?.statistics}
            loading={clientStatsReports?.loading}
            show={clientStatsReports?.show}
          />
          <ClientTestCardSection
            client={selectedClient?.value?._id}
            viewId={viewId}
          /> */}
      </>
    </>
  );
};
