import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

import { Bar } from "react-chartjs-2";

export const RevenuePerMonthGraph = ({ labels, data }) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
  );

  const options = {
    indexAxis: "y",
    responsive: true,
    scales: {
      x: {
        ticks: {
          color: "#f1f1f1",
          crossAlign: "far",
          // mirror: true,
        },
        grid: {
          color: "#f1f1f13b",
        },
      },
      y: {
        ticks: {
          color: "#f1f1f1",
          crossAlign: "far",
          // mirror: true,
        },
        grid: {
          color: "#f1f1f13b",
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "Average Revenue Per Month",
      },
      datalabels: {
        color: "#fff",
        anchor: "end",
        clamp: true,
        align: "left",
        labels: {
          value: {
            color: "#000",
          },
        },
        font: {
          weight: "500",
        },
        formatter: function (value, context) {
          return ` ${value.toLocaleString()}`;
        },
      },
    },
  };
  const colorOptions = ["#00CC71"];
  const dataset = {
    labels,
    datasets: [
      {
        data,
        backgroundColor: colorOptions,
        borderRadius: 5,
        borderThickness: 1,
        borderWidth: 1,
        borderColors: "#fff",
      },
    ],
  };

  return <Bar options={options} data={dataset} />;
};
