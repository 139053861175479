import React, { useEffect, useRef, useState } from "react";
import "./BusinessCaseData.scss";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import parse from "html-react-parser";
import "@splidejs/react-splide/css";
import {
  checkAdditionalRevenue,
  CURRENCYSYMBOLSMAP,
} from "../../../Libs/Utilities";
import { Button } from "../../MiroComponents/Button/Button";

export default function BusinessCaseData({
  topLineMetrics = {},
  bottomLineMetrics,
  futureMetrics,
  businessCaseWithClv12M,
  businessData,
  futureCLP,
  instanctEffect,
  secondaryEffect,
  threeMonthCohort,
  months,
  labels,
  currencyCode = "USD",
}) {
  const splideRef = useRef(null);
  const [activeLabel, setActiveLabel] = useState("businessData");

  const MetricCard = ({ title = "", data = [] }) => {
    return (
      <div className="metrics-card">
        <h2> {title}</h2>
        <ul className="flex flex-column">
          {data.map((item, index) => (
            <li
              key={index}
              className="inline-flex justify-space-between border-bottom"
            >
              <h3 className={item?.bold ? `weight800` : ``}>{item.title}</h3>
              <span className={item?.bold ? `weight800` : ``}>
                {item?.numberFormating ? (
                  checkAdditionalRevenue(
                    item.value,
                    null,
                    CURRENCYSYMBOLSMAP[currencyCode],
                    null,
                    true
                  )
                ) : (
                  <>
                    {CURRENCYSYMBOLSMAP[currencyCode]}
                    {item.value}
                  </>
                )}
              </span>
            </li>
          ))}
        </ul>
      </div>
    );
  };
  const options = {
    type: "slide",
    perPage: 2,
    autoWidth: true,
    arrows: false,
    pagination: true,
  };

  return (
    <div className="business-case-validation-wrapper section-pd">
      <div className="business-case-validation-inner">
        <h1>Business Case Validation</h1>
        <div className="business-case-data flex flex-wrap justify-space-between">
          <MetricCard
            title="Top Line Metrics"
            data={[
              {
                title: "Revenue impact while Testing",
                value: topLineMetrics?.revenueImpactWhileTesting,
              },
              {
                title: "Revenue impact after Testing",
                value: topLineMetrics?.revenueImpactAfterTesting,
              },
              {
                title: "Realized Add CLTV",
                value: topLineMetrics?.realizedAddClV,
              },
              {
                title: "Total Add Revenue",
                value: topLineMetrics?.totalAddRevenue,
                bold: true,
                numberFormating: true,
              },
            ]}
          />
          <MetricCard
            title="Bottom Line Metrics"
            data={[
              {
                title: "Realized Profit while Testing & After Testing",
                value: bottomLineMetrics?.realizedProfitWhileAndAfterTesting,
              },
              {
                title: "Realized CLP",
                value: bottomLineMetrics?.realizedCLP,
              },
              {
                title: "Total Realized Profit",
                value: bottomLineMetrics?.totalRealizedCLP,
                bold: true,
                numberFormating: true,
              },
            ]}
          />
          <MetricCard
            title="Future Metrics"
            data={[
              {
                title: "Add CLTV",
                value: futureMetrics?.additionalCLTV,
              },
              {
                title: "Add CLP",
                value: futureMetrics?.additionalCLP,
              },
            ]}
          />
        </div>

        <div className="business-case-data margin-bottom-auto">
          <div className="filter-labels flex flex-wrap justify-end">
            {labels?.map((label, index) => (
              <Button
                onClick={() => {
                  setActiveLabel(label.value);
                  splideRef.current.go(index);
                  // if (index === 0) {
                  //   splideRef.current.go(index);
                  // } else {
                  //   splideRef.current.go(index + 1);
                  // }
                  var selectedkey = document.getElementById(label.value);
                  if (selectedkey) {
                    selectedkey.classList.add("activeslide");
                    setTimeout(() => {
                      selectedkey.classList.remove("activeslide");
                    }, 1000);
                  }
                }}
                data-label={label.value}
                className={`smart-pill small label-index-${index} ${
                  activeLabel === label.value ? `active` : ``
                }`}
                key={index}
              >
                {index + 1}. {label.key}
              </Button>
            ))}
          </div>
          <div className="report-table-wrapper">
            <div className="report-data-table no-overflow flex">
              <div className="table-content-wrapper">
                <div className="table-heading">
                  <h3
                    style={{
                      opacity: 0,
                    }}
                  >
                    Revenue <br /> (BQ Data)
                  </h3>
                </div>
                <table>
                  <tbody>
                    <tr className="header">
                      <td>Month</td>
                    </tr>
                    {months?.map((month, index) => (
                      <tr key={index} className="data">
                        <td>{month}</td>
                      </tr>
                    ))}
                    <tr className="data total">
                      <td>Total</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <Splide
                options={options}
                ref={splideRef}
                className="splidewithoverflow"
                onMoved={(slide, newIndex) => {
                  const element = document.querySelector(
                    `.filter-labels .label-index-${newIndex}`
                  );
                  if (element) {
                    setActiveLabel(element.getAttribute("data-label"));
                  }
                }}
              >
                {/* <SplideSlide>
                  
                </SplideSlide> */}
                <SplideSlide>
                  <div
                    className="table-content-wrapper"
                    id={businessData?.label?.value}
                  >
                    <div className="table-heading">
                      <h3> {businessData?.label?.key}</h3>
                    </div>
                    <table>
                      <tbody>
                        {businessData?.data?.map((data, index) => (
                          <React.Fragment key={index}>
                            {index === 0 && (
                              <tr className="header">
                                {/* <td>{businessData?.datalabels?.serviceCost}</td> */}
                                <td>{businessData?.datalabels?.orders}</td>
                                <td>{businessData?.datalabels?.aov}</td>
                                <td>
                                  {parse(businessData?.datalabels?.revenue)}
                                </td>
                              </tr>
                            )}
                            <tr key={index} className="data">
                              {/* <td>{data?.serviceCost?.toLocaleString()}</td> */}
                              <td>{data?.orders?.toLocaleString()}</td>
                              <td>
                                {CURRENCYSYMBOLSMAP[currencyCode]}
                                {data?.aov}
                              </td>
                              <td>
                                {CURRENCYSYMBOLSMAP[currencyCode]}
                                {data?.revenue?.toLocaleString()}
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}
                        <tr className="data total">
                          {/* <td>
                            {businessData?.total?.serviceCost?.toLocaleString()}
                          </td> */}
                          <td>
                            {businessData?.total?.orders?.toLocaleString()}
                          </td>
                          <td>
                            {CURRENCYSYMBOLSMAP[currencyCode]}
                            {businessData?.total?.aov?.toLocaleString()}
                          </td>
                          <td>
                            {CURRENCYSYMBOLSMAP[currencyCode]}
                            {businessData?.total?.revenue?.toLocaleString()}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </SplideSlide>
                <SplideSlide>
                  <div
                    className="table-content-wrapper"
                    id={instanctEffect?.label?.value}
                  >
                    <div className="table-heading">
                      <h3
                        style={{
                          opacity: 0,
                        }}
                      >
                        Effect
                      </h3>
                    </div>
                    <table>
                      <tbody>
                        {instanctEffect?.data?.map((data, index) => (
                          <React.Fragment key={index}>
                            {index === 0 && (
                              <tr className="header" colSpan="2">
                                <td>
                                  {parse(
                                    instanctEffect?.datalabels
                                      ?.effectWhileTesting
                                  )}
                                </td>
                              </tr>
                            )}
                            <tr key={index} className="data">
                              <td>
                                {CURRENCYSYMBOLSMAP[currencyCode]}
                                {data?.effectWhileTesting?.toLocaleString()}
                              </td>
                              <td>{data?.instantEffect}%</td>
                            </tr>
                          </React.Fragment>
                        ))}
                        <tr className="data total">
                          <td>
                            {CURRENCYSYMBOLSMAP[currencyCode]}
                            {instanctEffect?.total?.effectWhileTesting?.toLocaleString()}
                          </td>
                          <td>{instanctEffect?.total?.instantEffect}% </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </SplideSlide>
                <SplideSlide>
                  <div
                    className="table-content-wrapper"
                    id={secondaryEffect?.label?.value}
                  >
                    <div className="table-heading">
                      <h3>
                        {" "}
                        {secondaryEffect?.label?.key} <sup>1</sup>
                      </h3>
                    </div>
                    <table>
                      <tbody>
                        {secondaryEffect?.data?.map((data, index) => (
                          <React.Fragment key={index}>
                            {index === 0 && (
                              <tr className="header">
                                <td colSpan="2">
                                  {
                                    secondaryEffect?.datalabels
                                      ?.effectForNextMonth
                                  }
                                </td>
                                <td>
                                  {parse(
                                    secondaryEffect?.datalabels
                                      ?.additionalTransactionNextMonth
                                  )}
                                </td>
                                <td>
                                  {parse(
                                    secondaryEffect?.datalabels
                                      ?.effectFor3Months
                                  )}
                                </td>
                              </tr>
                            )}
                            <tr key={index} className="data">
                              <td>
                                {CURRENCYSYMBOLSMAP[currencyCode]}
                                {data?.effectForNextMonth?.toLocaleString()}
                              </td>
                              <td>{data?.secondaryEffect}%</td>
                              <td>
                                {data?.additionalTransactionNextMonth?.toLocaleString()}
                              </td>
                              <td>
                                {CURRENCYSYMBOLSMAP[currencyCode]}
                                {data?.effectFor3Months?.toLocaleString()}
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}
                        <tr className="data total">
                          <td>
                            {CURRENCYSYMBOLSMAP[currencyCode]}
                            {secondaryEffect?.total?.effectForNextMonth?.toLocaleString()}
                          </td>
                          <td>{secondaryEffect?.total?.secondaryEffect}%</td>
                          <td>
                            {secondaryEffect?.total?.additionalTransactionNextMonth?.toLocaleString()}
                          </td>
                          <td>
                            {CURRENCYSYMBOLSMAP[currencyCode]}
                            {secondaryEffect?.total?.effectFor3Months?.toLocaleString()}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </SplideSlide>
                <SplideSlide>
                  <div
                    className="table-content-wrapper"
                    id={threeMonthCohort?.label?.value}
                  >
                    <div className="table-heading">
                      <h3> {threeMonthCohort?.label?.key}</h3>
                    </div>
                    <table>
                      <tbody>
                        {threeMonthCohort?.data?.map((data, index) => (
                          <React.Fragment key={index}>
                            {index === 0 && (
                              <tr className="header">
                                <td>
                                  {parse(
                                    threeMonthCohort?.datalabels
                                      ?.revenueThreeMonthCohort
                                  )}
                                </td>
                                {/* <td>
                                  {parse(
                                    threeMonthCohort?.datalabels?.serviceCost
                                  )}
                                </td> */}
                                <td>
                                  {parse(
                                    threeMonthCohort?.datalabels
                                      ?.profitThreeMonthCohort
                                  )}
                                </td>
                              </tr>
                            )}
                            <tr key={index} className="data">
                              <td>
                                {CURRENCYSYMBOLSMAP[currencyCode]}
                                {data?.revenueThreeMonthCohort?.toLocaleString()}
                              </td>
                              {/* <td>
                                {CURRENCYSYMBOLSMAP[currencyCode]}
                                {data?.serviceCost?.toLocaleString()}
                              </td> */}
                              <td>
                                {CURRENCYSYMBOLSMAP[currencyCode]}
                                {data?.profitThreeMonthCohort?.toLocaleString()}
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}
                        <tr className="data total">
                          <td>
                            {CURRENCYSYMBOLSMAP[currencyCode]}
                            {threeMonthCohort?.total?.revenueThreeMonthCohort?.toLocaleString()}
                          </td>
                          {/* <td>
                            {CURRENCYSYMBOLSMAP[currencyCode]}
                            {threeMonthCohort?.total?.serviceCost?.toLocaleString()}
                          </td> */}
                          <td>
                            {CURRENCYSYMBOLSMAP[currencyCode]}
                            {threeMonthCohort?.total?.profitThreeMonthCohort?.toLocaleString()}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </SplideSlide>
                <SplideSlide>
                  <div
                    className="table-content-wrapper"
                    id={businessCaseWithClv12M?.label?.value}
                  >
                    <div className="table-heading">
                      <h3>
                        {" "}
                        {businessCaseWithClv12M?.label?.key} <sup>2</sup>
                      </h3>
                    </div>
                    <table>
                      <tbody>
                        {businessCaseWithClv12M?.data?.map((data, index) => (
                          <React.Fragment key={index}>
                            {index === 0 && (
                              <tr className="header">
                                <td>
                                  {parse(
                                    businessCaseWithClv12M?.datalabels
                                      ?.additionalclv365d
                                  )}
                                </td>
                                <td>
                                  {parse(
                                    businessCaseWithClv12M?.datalabels
                                      ?.additionalclp365d
                                  )}
                                </td>
                                <td>
                                  {parse(
                                    businessCaseWithClv12M?.datalabels
                                      ?.realizedPercentage
                                  )}
                                </td>
                                <td>
                                  {parse(
                                    businessCaseWithClv12M?.datalabels
                                      ?.allRealizedProfit
                                  )}
                                </td>
                              </tr>
                            )}
                            <tr key={index} className="data">
                              <td>
                                {CURRENCYSYMBOLSMAP[currencyCode]}
                                {data?.additionalclv365d?.toLocaleString()}
                              </td>
                              <td>
                                {CURRENCYSYMBOLSMAP[currencyCode]}
                                {data?.additionalclp365d?.toLocaleString()}
                              </td>
                              <td>{data?.realizedPercentage}%</td>
                              <td>
                                {CURRENCYSYMBOLSMAP[currencyCode]}
                                {data?.allRealizedProfit?.toLocaleString()}
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}
                        <tr className="data total">
                          <td>
                            {CURRENCYSYMBOLSMAP[currencyCode]}
                            {businessCaseWithClv12M?.total?.additionalclv365d?.toLocaleString()}
                          </td>
                          <td>
                            {CURRENCYSYMBOLSMAP[currencyCode]}
                            {businessCaseWithClv12M?.total?.additionalclp365d?.toLocaleString()}
                          </td>
                          <td>
                            {businessCaseWithClv12M?.total
                              ?.realizedPercentage && (
                              <>
                                {
                                  businessCaseWithClv12M?.total
                                    ?.realizedPercentage
                                }
                                %
                              </>
                            )}
                          </td>
                          <td>
                            {CURRENCYSYMBOLSMAP[currencyCode]}
                            {businessCaseWithClv12M?.total?.allRealizedProfit?.toLocaleString()}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </SplideSlide>
                <SplideSlide>
                  <div
                    className="table-content-wrapper"
                    id={futureCLP?.label?.value}
                  >
                    <div className="table-heading">
                      <h3>
                        {" "}
                        {futureCLP?.label?.key} <sup>3</sup>
                      </h3>
                    </div>
                    <table>
                      <tbody>
                        {futureCLP?.data?.map((data, index) => (
                          <React.Fragment key={index}>
                            {index === 0 && (
                              <tr className="header">
                                <td>
                                  {parse(futureCLP?.datalabels?.futureCLP)}
                                </td>
                                <td>
                                  {parse(futureCLP?.datalabels?.serviceCost)}
                                </td>
                              </tr>
                            )}
                            <tr key={index} className="data">
                              <td>
                                {CURRENCYSYMBOLSMAP[currencyCode]}
                                {data?.futureCLP?.toLocaleString()}
                              </td>
                              <td>
                                {CURRENCYSYMBOLSMAP[currencyCode]}
                                {data?.serviceCost?.toLocaleString()}
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}
                        <tr className="data total">
                          <td>
                            {CURRENCYSYMBOLSMAP[currencyCode]}
                            {futureCLP?.total?.futureCLP?.toLocaleString()}
                          </td>
                          <td>
                            {CURRENCYSYMBOLSMAP[currencyCode]}
                            {futureCLP?.total?.serviceCost?.toLocaleString()}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </SplideSlide>
              </Splide>
            </div>
          </div>

          <div className="business-case-text">
            <ul className="flex flex-column">
              <li className="flex">
                <span>1 -</span>
                <span>
                  Calculation: Instant Effect based on Increased RPU <br />
                  Calculation: Secondary Effect based on Increased CVR+AOV for 3
                  months
                </span>
              </li>

              <li className="flex">
                <span>2 - </span>
                <span>
                  Future Projection: Effect based on additional conversions for
                  CLTV + CLP <br />
                  Combined Projection on Additional Revenue + Profit based on
                  CVR, AOV & CLP
                </span>
              </li>

              <li className="flex">
                <span>3 - </span>
                <span>Future Projection of Incoming CLP + CRO Work</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
