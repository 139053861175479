import { useLazyQuery, useQuery } from "@apollo/client";
import { subDays, subMonths } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import {
  VscAccount,
  VscArrowUp,
  VscChecklist,
  VscDiff,
  VscRocket,
  VscUngroupByRefType,
} from "react-icons/vsc";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  ADMINDASHBOARDSTATS,
  GETCLIENTS,
  GETCLIENTSPORTFOLIO,
} from "../../../Graphql/Queries";
import MyRoutes from "../../../Routes";
import MyLoader from "../../MiroComponents/PreLoader";
import { AdminStats } from "../../Shared/AdminStats";
import { DateRangeSelectorComponent } from "../../Shared/DateRangeSelectorComponent";
import { RevenuePerMonthGraph } from "./AdminComponents/RevenuePerMonthGraph";
import { customStyles } from "../../../Libs/TableCustomStyles";
import DataTable from "react-data-table-component";
import { Button } from "../../MiroComponents/Button/Button";
import ContentSkeleton from "../../NewComponents/Skeletons/ContentSkeleton";

function Dashboard() {
  const [myProfile, is_client, is_admin, is_user] = useOutletContext();
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: subMonths(new Date(), 6),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [data, setData] = useState({
    loading: true,
  });
  const [clientPortfolio, setClientPortfolio] = useState({
    loading: true,
  });
  const navigate = useNavigate();
  const [getStatsData] = useLazyQuery(ADMINDASHBOARDSTATS);
  const [getclientportfolio] = useLazyQuery(GETCLIENTSPORTFOLIO);

  const setAdminStats = useCallback(async () => {
    const { data, loading, error } = await getStatsData({
      variables: {
        from: selectionRange[0].startDate,
        to: selectionRange[0].endDate,
        client: null,
        active: true,
      },
    });
    setData({
      ...data,
      adminDashboardStatistics: data?.adminDashboardStatistics,
      getAdminStats: data?.getAdminStats,
      revenuePerMonth: data?.revenuePerMonth,
      loading,
      error,
    });
  }, [selectionRange]);

  const callBackWhenClose = () => {
    setShowCalendar(false);
  };

  useEffect(() => {
    setAdminStats();
  }, [setAdminStats]);

  useEffect(() => {
    (async () => {
      const { data, loading, error } = await getclientportfolio();
      setClientPortfolio({
        clientPortfolio: data?.clientPortfolio,
        loading,
        error,
      });
    })();
  }, [getclientportfolio]);

  const checkNaN = (wt, et) => {
    const _winrate = (Number(wt) / Number(et)) * 100;
    return (
      <div className="fw-light">
        {wt} / {et}
        <strong>
          {" "}
          ({!isNaN(_winrate) && isFinite(_winrate) ? _winrate.toFixed(2) : 0} %)
        </strong>
      </div>
    );
  };

  const PerformanceCard = ({
    changeIn = null,
    title = "",
    value = "0",
    symbol = "",
    active = false,
    loading = true,
  }) => {
    if (loading)
      return (
        <div className={`p-card`}>
          <ContentSkeleton />
        </div>
      );
    return (
      <div className={`p-card ${active ? `active neon-shadow` : ``}`}>
        <div className="p-card-inner">
          <div className="p-card-top inline-flex align-center">
            <span className="change-in">{/* <ArrowUpIcon /> */}</span>

            <p> {title}</p>
          </div>
          <div className="p-card-bottom inline-flex align-center" title={value}>
            {title === "Return on Invest" && value <= 0 ? (
              <>
                <span> - </span> <span> to be updated</span>
              </>
            ) : (
              <>
                <span>{symbol}</span> <span> {value} </span>
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  // if (data.loading || clientPortfolio?.loading) return <MyLoader />;
  return (
    <>
      <div className="conversion-performance section-pd">
        <div>
          <h1>Performance Overview</h1>
          <div className="p-card-flex flex">
            <PerformanceCard
              loading={data?.loading || !data?.getAdminStats?.activeClients}
              {...{
                title: "Active Clients",
                value: data?.getAdminStats?.activeClients?.toLocaleString(),
                symbol: "",
              }}
            />
            <PerformanceCard
              loading={data?.loading || !data?.getAdminStats?.activeClients}
              {...{
                title: "Overall Win-rate",
                value: (
                  (data?.getAdminStats?.winningTests /
                    data?.getAdminStats?.endedTests) *
                  100
                ).toFixed(2),
                symbol: "%",
              }}
            />
            {is_admin && (
              <>
                <PerformanceCard
                  loading={data?.loading || !data?.getAdminStats?.yearlyRevenue}
                  {...{
                    title: "Yearly Revenue",
                    value: data?.getAdminStats?.yearlyRevenue,
                    symbol: "",
                  }}
                />
                <PerformanceCard
                  loading={
                    data?.loading || !data?.getAdminStats?.monthlyRevenue
                  }
                  {...{
                    title: "Monthly Revenue",
                    value: data?.getAdminStats?.monthlyRevenue,
                    symbol: "",
                  }}
                />
              </>
            )}
          </div>
        </div>
      </div>

      {(is_admin || is_user) && (
        <div className="section-pd">
          <div className="">
            {/* <div className="">
              <DateRangeSelectorComponent
                showCalendar={showCalendar}
                setShowCalendar={setShowCalendar}
                selectionRange={selectionRange}
                setSelectionRange={setSelectionRange}
                callBackWhenClose={callBackWhenClose}
                className="dark-calendar"
                classNameWrapper="dark-calendar-wrapper"
                color={"#000"}
              />
            </div> */}
            <RevenuePerMonthGraph
              labels={data?.revenuePerMonth?.map(
                (rpm) => `${rpm.month}, ${rpm.year}`
              )}
              data={data?.revenuePerMonth?.map((rpm) => rpm.revenue)}
            />
          </div>
        </div>
      )}

      {is_admin && (
        <div className="conversion-performance section-pd">
          <div>
            <h1>Overall Portfolio Performance </h1>
            <div className="p-card-flex flex">
              <PerformanceCard
                loading={
                  clientPortfolio?.loading ||
                  !clientPortfolio?.clientPortfolio?.successRate
                }
                {...{
                  title: "Success Rate",
                  value: clientPortfolio?.clientPortfolio?.successRate,
                  symbol: "%",
                }}
              />
              <PerformanceCard
                loading={
                  clientPortfolio?.loading ||
                  !clientPortfolio?.clientPortfolio?.endedTest
                }
                {...{
                  title: "Ended Tests",
                  value: clientPortfolio?.clientPortfolio?.endedTest,
                  symbol: "",
                }}
              />
              <PerformanceCard
                loading={
                  clientPortfolio?.loading ||
                  !clientPortfolio?.clientPortfolio
                    ?.averageUpliftSuccessfullTests
                }
                {...{
                  title: "Avg. Uplift on CR of successful Tests",
                  value:
                    clientPortfolio?.clientPortfolio
                      ?.averageUpliftSuccessfullTests,
                  symbol: "%",
                }}
              />
              <PerformanceCard
                loading={
                  clientPortfolio?.loading ||
                  !clientPortfolio?.clientPortfolio?.monthlyRevenue
                }
                {...{
                  title: "Monthly Revenue",
                  value: clientPortfolio?.clientPortfolio?.monthlyRevenue,
                  symbol: "",
                }}
              />
              <PerformanceCard
                loading={
                  clientPortfolio?.loading ||
                  !clientPortfolio?.clientPortfolio?.yearlyRevenue
                }
                {...{
                  title: "Yearly Revenue",
                  value: clientPortfolio?.clientPortfolio?.yearlyRevenue,
                  symbol: "",
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Dashboard;
