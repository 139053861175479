import React, { useCallback, useEffect, useMemo, useState, memo } from "react";

import {
  differenceInCalendarDays,
  format,
  setDate,
  subDays,
  subMonths,
} from "date-fns";
import MyLoader from "../MiroComponents/PreLoader";
import { DateRangeSelectorComponent } from "../Shared/DateRangeSelectorComponent";
import { DownArrow, UpArrow } from "../Shared/Icons";
import { PreDefinedDates } from "../../Libs/Utilities";
import Select from "react-select";
import { AdditionalRevenueAfterImplementation } from "./ClientGraphs/AdditionalRevenueAfterImplementation";
import { useLazyQuery } from "@apollo/client";
import { CLIENTPEFORMANCEOVERVIEW } from "../../Graphql/Queries";
import NoDataFound from "../MiroComponents/NoDataFound/NoDataFound";

export function ClientStatsComponent({ client, viewId }) {
  const [clientTestsData, setClientTestsData] = useState(null);
  const [currentGraph, setCurrentGraph] = useState(
    "additionrevenueafterimplementation"
  );
  const [showCalendar, setShowCalendar] = useState(false);
  const [dateSelected, setDateSelected] = useState(null);
  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: subMonths(new Date(), 12),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [clientPerformance] = useLazyQuery(CLIENTPEFORMANCEOVERVIEW);
  const callBackWhenClose = () => {
    setShowCalendar(false);
  };

  const clientPerformanceOverviewQuery = useCallback(async () => {
    setClientTestsData((clientTestsData) => ({
      ...clientTestsData,
      loading: true,
    }));
    if (client && viewId) {
      try {
        const {
          data: { clientPerformanceOverview },
          loading,
        } = await clientPerformance({
          variables: {
            client,
            viewId,
          },
        });
        setClientTestsData({
          show: !loading,
          loading,
          ...clientPerformanceOverview,
        });
      } catch (error) {
        setClientTestsData({
          loading: false,
          error: error.message,
        });
      }
    }
  }, [client, viewId]);

  useEffect(() => {
    clientPerformanceOverviewQuery();
  }, [clientPerformanceOverviewQuery]);

  useEffect(() => {
    if (dateSelected) {
      const { startDate, endDate } = JSON.parse(dateSelected?.value);
      setSelectionRange([
        {
          key: "selection",
          startDate: new Date(startDate),
          endDate: new Date(endDate),
        },
      ]);
    }
  }, [dateSelected]);

  const IncreaseDecreaseInValue = ({
    value,
    accountvalue,
    currency,
    type,
    loading,
  }) => {
    if (loading) {
      return <span style={{ color: "#fff" }}>Loading...</span>;
    }
    value = value && !isNaN(value) && isFinite(value) ? value : 0;
    accountvalue =
      accountvalue && !isNaN(accountvalue) && isFinite(accountvalue)
        ? accountvalue
        : 0;
    return (
      <>
        <div className="analytics-amount">
          {currency && <span className="currency-symbol"> {currency} </span>}
          {accountvalue?.toLocaleString()}
          {type}
        </div>
        {value > 0 && (
          <div
            className={`analytics-percentage ${
              value > 0 ? `analytics-increment` : `analytics-decrement`
            }`}
          >
            {value > 0 ? <UpArrow /> : <DownArrow />}
            <span className="operator"> {value > 0 ? `+` : ``} </span>
            <span className="figure">{value}%</span>
          </div>
        )}
      </>
    );
  };
  if (!viewId) return <NoDataFound title="Please Select Property!" />;
  if (clientTestsData?.loading) return <MyLoader />;
  if (clientTestsData?.error)
    return (
      <div>
        <h5 className="text-danger">
          {" "}
          There was a problem connecting with GA4 account. Please contact admin
          for more details.
        </h5>
        <p className="text-white"> {clientTestsData?.message}</p>
      </div>
    );
  return (
    <React.Fragment>
      <section className="client-logo-wrap">
        <div className="client-logo">
          {clientTestsData?.logo && (
            <img src={clientTestsData?.logo} alt="Client Logo" />
          )}
        </div>
      </section>
      <section className="client-stats">
        <div className="client-analytics">
          <div className="client-analytics-inner">
            <h2 className="analytics-text">Performance Overview</h2>
            <div className="analytics-cards-list">
              <div
                className={`analytics-card ${
                  currentGraph === "additionrevenueafterimplementation"
                    ? `analytics-card-active`
                    : ``
                }`}
                onClick={(e) =>
                  setCurrentGraph("additionrevenueafterimplementation")
                }
              >
                <h4 className="analytics-card-title">
                  Additional revenue After Implementation
                </h4>
                <div className="analytics-card-figures">
                  <IncreaseDecreaseInValue
                    accountvalue={Number(
                      clientTestsData?.additionalRevenueAfterImplementation
                    )}
                    loading={false}
                    value={
                      clientTestsData?.increaseInRevenueAfterImplementation
                    }
                    type=""
                    currency={clientTestsData?.currency}
                  />
                </div>
              </div>

              <div
                className={`analytics-card ${
                  currentGraph === "shopconversionrate"
                    ? `analytics-card-active`
                    : ``
                }`}
                onClick={(e) => setCurrentGraph("shopconversionrate")}
              >
                <h4 className="analytics-card-title">Shop Conversion-Rate</h4>
                <div className="analytics-card-figures">
                  <IncreaseDecreaseInValue
                    loading={false}
                    accountvalue={clientTestsData?.shopConversionRate}
                    type="%"
                  />
                </div>
              </div>

              <div
                className={`analytics-card ${
                  currentGraph === "annualygeneratedrevenue"
                    ? `analytics-card-active`
                    : ``
                }`}
                onClick={(e) => setCurrentGraph("annualygeneratedrevenue")}
              >
                <h4 className="analytics-card-title">
                  Annually generated revenue
                </h4>
                <div className="analytics-card-figures">
                  <IncreaseDecreaseInValue
                    loading={false}
                    accountvalue={Number(clientTestsData?.totalCroValue)}
                    type=""
                    currency={clientTestsData?.currency}
                  />
                </div>
              </div>

              <div
                className={`analytics-card ${
                  currentGraph === "returnoninvest"
                    ? `analytics-card-active`
                    : ``
                }`}
                onClick={(e) => setCurrentGraph("returnoninvest")}
              >
                <h4 className="analytics-card-title">Return on Invest</h4>
                <div className="analytics-card-figures">
                  <IncreaseDecreaseInValue
                    loading={false}
                    accountvalue={Number(clientTestsData?.returnOnInvestment)}
                    type="x"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="client-average-revenue">
          <div className="average-revenue-inner">
            <h2 className="average-revenue-title">
              Additional Revenue Per Month
            </h2>
            <div className="date-select-range-wrapper">
              <div className="average-revenue-date-selector">
                <Select
                  value={dateSelected}
                  className="acc-newselectwrapper"
                  classNamePrefix="acc-newselect"
                  placeholder="Date"
                  // menuIsOpen={true}
                  onChange={(date) => setDateSelected(date)}
                  options={PreDefinedDates()?.map((dates) => {
                    return {
                      label: dates?.label,
                      value: JSON.stringify(dates?.value),
                    };
                  })}
                  isSearchable={false}
                />
                <DateRangeSelectorComponent
                  showCalendar={showCalendar}
                  setShowCalendar={setShowCalendar}
                  selectionRange={selectionRange}
                  setSelectionRange={setSelectionRange}
                  callBackWhenClose={callBackWhenClose}
                  showDays={false}
                  className="dark-calendar"
                  classNameWrapper="dark-calendar-wrapper"
                  color={"#fff"}
                />
              </div>
              <div className="graph">
                <AdditionalRevenueAfterImplementation
                  client={client}
                  viewId={viewId}
                  selectionRange={selectionRange}
                  showCalendar={showCalendar}
                />
                {/* <img src="./assets/graph.png" alt="graph image" /> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export const ClientStats = React.memo(ClientStatsComponent);
