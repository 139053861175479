import { useMutation } from "@apollo/client";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { toast } from "react-toastify";
import { DEACTIVATEREPORT } from "../../Graphql/Mutations";
import { CrossIcon2 } from "../Shared/Icons";
import moment from "moment";
import { Button } from "../MiroComponents/Button/Button";

export const ReportHistories = ({
  reportsHistory,
  getReportsHistoryQuery,
  setReportDetails,
  setCurrentReportType,
  currentReportType,
  refetchReportHistory,
  platform,
  setIncludeProducts = () => void 0,
  setFile = () => void 0,
}) => {
  // const filterExpressions = [
  //   {
  //     name: "EXACT",
  //     sign: "=",
  //   },
  //   {
  //     name: "NOT EXACT",
  //     sign: "!=",
  //   },
  //   {
  //     name: "CONTAINS",
  //     sign: "@",
  //   },
  //   {
  //     name: "NOT CONTAINS",
  //     sign: "!@",
  //   },
  //   {
  //     name: "STARTS WITH",
  //     sign: "^",
  //   },
  //   {
  //     name: "ENDS WITH",
  //     sign: "$",
  //   },
  //   { name: "==", sign: "==" },
  //   { name: "!=", sign: "!=" },
  //   {
  //     name: "<",
  //     sign: "<",
  //   },
  //   {
  //     name: ">",
  //     sign: ">",
  //   },
  //   {
  //     name: "<=",
  //     sign: "<=",
  //   },
  //   {
  //     name: ">=",
  //     sign: ">=",
  //   },
  // ];

  const reportHistoryTitle = ({ platform, title }) => {
    if (platform === "GA4" || platform === "BQ") {
      title = title?.trim() === "" || !title ? "visitorType=All Users" : title;
      const rawTitle = title.split(";");
      const splitByRegex = /(=|!=|@|!@|^|$|==|!=|<|>|<=|>=)/g;
      const titlearr = rawTitle.map((t) => {
        return t.split(splitByRegex);
      });

      return (
        <>
          {titlearr.map((t, index) => {
            let segmentKey = t[0].split(/(?=[A-Z])/).join(" ");
            let segmentValue = t.slice(1).join(" ");
            return (
              <div className="segment-names-capitalised" key={index}>
                <p className="segment-key mb-0">{segmentKey}</p>
                <p className="segment-value mb-0">{segmentValue}</p>
              </div>
            );
          })}
        </>
      );
    }
    return title;
  };

  const [deletereporthistory] = useMutation(DEACTIVATEREPORT);
  return (
    <div className="experiment-data-inner">
      <div className="experiment-heading-divider">
        <div className="section-heading-data">
          <h1 className="section-heading">History</h1>
          {/* <span className="section-heading-subtitle">(to-date)</span> */}
        </div>
      </div>
      <div className="experiment-data-navbar history-tabs">
        <Splide
          className="splide-theme-codebase"
          aria-label="My Favorite Images"
          options={{
            type: "slide",
            perPage: 3,
            pagination: false,
            arrows: true,
            gap: "10px",
            breakpoints: {
              1279: {
                pagination: true,
                arrows: false,
              },
              991: {
                perPage: 2,
              },
              767: {
                perPage: 1,
                pagination: true,
                arrows: false,
              },
            },
          }}
        >
          {reportsHistory?.data?.map((history, i) => (
            <SplideSlide key={i}>
              <div
                key={i}
                className={`${
                  history?._id === currentReportType?.id
                    ? `currentactivehistory`
                    : ``
                } live-report-history-tab-wrapper`}
              >
                <div>
                  <span className="rp-context">
                    <span
                      data-tip={history.filters}
                      className="history-tabs-wrapper"
                    >
                      {reportHistoryTitle({ platform, title: history.filters })}
                    </span>
                    <span
                      className="remove-hitsory-tab remove-hitsory-tab-new"
                      onClick={async (e) => {
                        await deletereporthistory({
                          variables: {
                            id: history?._id,
                          },
                        });
                        getReportsHistoryQuery();
                        toast.success("History Deleted!");
                      }}
                    >
                      {" "}
                      <CrossIcon2 />
                    </span>
                  </span>
                  <span className="rp-ctas">
                    <span className="history-date">
                      {moment(history?.updatedAt).fromNow()}
                    </span>
                    <div className="history-controllers-btns">
                      <Button
                        className={`smart-button small elipses ${
                          history?._id === currentReportType?.id &&
                          currentReportType?.type === "history"
                            ? `active`
                            : ``
                        }`}
                        onClick={(e) => {
                          setIncludeProducts(false);
                          setReportDetails(null);
                          setCurrentReportType({
                            id: history?._id,
                            type: "history",
                          });
                          setReportDetails(history.report);
                        }}
                      >
                        History
                      </Button>
                      <Button
                        className={`smart-button small elipses ${
                          history?._id === currentReportType?.id &&
                          currentReportType?.type === "live"
                            ? `active`
                            : ``
                        }`}
                        onClick={async (e) => {
                          setIncludeProducts(false);
                          setReportDetails(null);
                          refetchReportHistory(history?._id);
                          // setSelectionRange(dateRanges);
                          // console.log(history);
                          // const { data, loading } = await refetchreport({
                          //   variables: {
                          //     id: history?._id,
                          //     endDate: moment(new Date()).format("yyyy-MM-DD"),
                          //   },
                          // });
                          // setLoader(loading);
                          // const _ = experimentEndResults(
                          //   data?.refetchTemporaryReport?.response?.experiments,
                          //   _daterange
                          // );
                          // setReportDetails(
                          //   data?.refetchTemporaryReport?.response
                          // );
                          // setCalculatedResults(_);
                          // getReportsHistoryQuery();
                        }}
                      >
                        Refetch
                      </Button>
                    </div>
                  </span>
                </div>
              </div>
            </SplideSlide>
          ))}
        </Splide>
      </div>
    </div>
  );
};
