import React, { useEffect, useState } from "react";
import "./TestsOverview.scss";
import { useLazyQuery } from "@apollo/client";
import { CLIENTSTATISTICS } from "../../../Graphql/QueriesNew";
import ContentSkeleton from "../Skeletons/ContentSkeleton";
export default function TestsOverview({ heading = "Test Overview", client }) {
  const [getclientstatistics] = useLazyQuery(CLIENTSTATISTICS);
  const [data, setData] = useState(null);
  useEffect(() => {
    (async () => {
      try {
        const { data, loading } = await getclientstatistics({
          variables: {
            client,
          },
        });
        setData({
          ...data?.clientTestsStatisticsV2,
          loading,
        });
      } catch (error) {
        console.log(error);
      }
    })();
  }, [client, getclientstatistics]);

  const InsightCard = ({ label = "", value = "", symbol = null }) => {
    return (
      <div className="insight-card flex">
        <div className="card-top">
          <p> {label}</p>
        </div>
        <div className="card-bottom">
          {value}
          {symbol}
        </div>
      </div>
    );
  };
  const SuccessRate = ({ label = "", value = "", symbol = null }) => (
    <div className="success-rt">
      <div className="card-top">
        <p> {label}:</p>
        <span>
          {" "}
          {value}
          {symbol}
        </span>
      </div>
      <div className="card-bottom">
        <p>of all tests were successful and adviced to be implemented</p>
      </div>
    </div>
  );
  if (!data || data.loading)
    return (
      <div>
        <ContentSkeleton />
      </div>
    );
  return (
    <div className="section-pd border-bottom">
      <h1>{heading}</h1>
      <div className="t-card-flex flex justify-space-between flex-wrap">
        <SuccessRate {...data?.statistics?.successRate} />
        <div className="insights-crds flex align-center justify-space-between flex-wrap">
          <InsightCard {...data?.statistics?.ended} />
          <InsightCard {...data?.statistics?.live} />
          <InsightCard {...data?.statistics?.draft} />
          <InsightCard {...data?.statistics?.avrupliftonwinningtests} />
        </div>
      </div>
    </div>
  );
}
