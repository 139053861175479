import { useEffect, useState } from "react";
import { useOutletContext, useSearchParams } from "react-router-dom";
import ExperimentsList from "../../NewComponents/ExperimentsList/ExperimentsList";
import TargetingModuleList from "../../NewComponents/TargetingModuleList/TargetingModuleList";
import useStore from "../../../Store";
import NoDataFound from "../../MiroComponents/NoDataFound/NoDataFound";

export const ABTestingDashboard = () => {
  const [searchParams, setSearchParams] = useSearchParams({});
  const [activeTab, setActiveTab] = useState("experiments");
  const selectedClient = useStore((state) => state.selectedClient);
  const [experimentsPagination, setExperimentsPagination] = useState({
    page: 1,
    size: 5,
    total: 0,
  });
  const _params = Object.fromEntries(searchParams);

  const [codeValue, setCodeValue] = useState({});

  if (!selectedClient) return <NoDataFound />;
  return (
    <>
      <ExperimentsList
        client={selectedClient?.value?._id}
        setSearchParams={setSearchParams}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        params={_params}
      />
      {activeTab === "targeting" && (
        <TargetingModuleList
          client={selectedClient?.value?._id}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      )}
    </>
  );
};
